import { ThemeProvider } from "@mui/material";
import { lazy, Suspense, useEffect, useRef, useState } from "react";
import {
  Switch,
  BrowserRouter as Router,
  Route,
  Redirect,
  useHistory,
} from "react-router-dom";
import { historyRef } from "src/Helpers/history";
import GlobalStyle from "./global-style";
import Loading from "./Components/Loading";
import { authContext, errorHandlerContext } from "./contexts";
import { useProvideAuth, useAuth, useProvideErrorHandler } from "./hooks";
import Landing from "./Pages/Landing";
import Terms from "./Pages/SiteLinks/Components/Terms";
import Verify from "./Pages/Verify";
import checkValidity from "src/Utils/UserPermission";
import Snackbar from "src/Components/ErrorHandler/Snackbar";
import { theme } from "src/theme";
import TagManager from "react-gtm-module";
import PdfViewer from "./Pages/Quotes/Components/pdf";

const tagManagerArgs = {
  gtmId: "G-SN0GM28678",
};
TagManager.initialize(tagManagerArgs);

type TDataLayer = {
  push: (data: Record<string, unknown>) => void;
};

type TWindowWithDataLayer = typeof window & {
  dataLayer: TDataLayer;
};

const PrivateRoutesList = [
  {
    key: 2,
    path: "/getQuote",
    exact: true,
    component: lazy(() => import("./Pages/GetQuotes")),
  },
  {
    key: 3,
    path: "/listofrates",
    exact: true,
    component: lazy(() => import("./Pages/QuotedList")),
  },
  {
    key: 4,
    path: "/quotes",
    exact: true,
    component: lazy(() => import("./Pages/Quotes")),
  },
  {
    key: 5,
    path: "/shipments",
    exact: true,
    component: lazy(() => import("./Pages/Shipments")),
  },
  {
    key: 6,
    path: "/rates",
    exact: true,
    component: lazy(() => import("./Pages/Rates")),
  },
  {
    key: 7,
    path: "/comparison",
    exact: true,
    component: lazy(() => import("./Pages/Comparison")),
  },
  {
    key: 8,
    path: "/templates",
    exact: true,
    component: lazy(() => import("./Pages/Templates")),
  },
  {
    key: 9,
    path: "/flights",
    exact: true,
    component: lazy(() => import("./Pages/Flights")),
  },
  {
    key: 10,
    path: "/manage-account",
    exact: true,
    component: lazy(() => import("./Pages/ManageAccount")),
  },
  {
    key: 11,
    path: "/admin",
    exact: true,
    component: lazy(() => import("./Pages/Admin")),
  },
  {
    key: 11.1,
    path: "/admins",
    exact: true,
    component: lazy(() => import("./Pages/Admin")),
  },
  {
    key: 11.2,
    path: "/fedagsa-members",
    exact: true,
    component: lazy(() => import("./Pages/Admin")),
  },
  {
    key: 11.3,
    path: "/searches",
    exact: true,
    component: lazy(() => import("./Pages/Admin")),
  },
  {
    key: 11.4,
    path: "/terms-and-conditions",
    exact: true,
    component: lazy(() => import("./Pages/Admin")),
  },
  {
    key: 11.5,
    path: "/abws",
    exact: true,
    component: lazy(() => import("./Pages/Admin")),
  },
  {
    key: 11.6,
    path: "/logos",
    exact: true,
    component: lazy(() => import("./Pages/Admin")),
  },
  {
    key: 11.7,
    path: "/maps",
    exact: true,
    component: lazy(() => import("./Pages/Maps")),
  },
  {
    key: 12,
    path: "/ssim",
    exact: true,
    component: lazy(() => import("./Pages/SSIM")),
  },
  {
    key: 13,
    path: "/ssim/:id",
    exact: true,
    component: lazy(() => import("./Pages/SSIM/Components/SSIMData")),
  },
  {
    key: 14,
    path: "/flight-details/:ssimHdrId",
    exact: true,
    component: lazy(() => import("./Pages/SSIM/Components/FlightDetails")),
  },
  {
    key: 15,
    path: "/gha-members",
    exact: true,
    component: lazy(() => import("./Pages/GHAs")),
  },
];

const PublicRoutesList = [
  {
    key: 1,
    path: "/signin",
    exact: true,
    component: lazy(() => import("./Pages/Signin")),
  },
  {
    key: 2,
    path: "/signUp",
    exact: true,
    component: lazy(() => import("./Pages/SignUp")),
  },
  {
    key: 2,
    path: "/companyVerfication",
    exact: true,
    component: lazy(() => import("./Pages/CompanyVerfication")),
  },
  {
    key: 3,
    path: "/forget-password",
    exact: true,
    component: lazy(() => import("./Pages/ForgetPassword")),
  },
  {
    key: 4,
    path: "/privacy-policy",
    exact: true,
    component: lazy(() => import("./Pages/SiteLinks/Components/PrivacyPolicy")),
  },
  {
    key: 5,
    path: "/cookie-policy",
    exact: true,
    component: lazy(() => import("./Pages/SiteLinks/Components/CookiePolicy")),
  },
  {
    key: 6,
    path: "/disclaimer",
    exact: true,
    component: lazy(() => import("./Pages/SiteLinks/Components/Disclaimer")),
  },
  {
    key: 7,
    path: "/team",
    exact: true,
    component: lazy(() => import("./Pages/SiteLinks/Components/Team")),
  },
  {
    key: 8,
    path: "/career",
    exact: true,
    component: lazy(() => import("./Pages/SiteLinks/Components/Career")),
  },
  {
    key: 9,
    path: "/blogs",
    exact: true,
    component: lazy(() => import("./Pages/SiteLinks/Components/Blogs")),
  },
  {
    key: 10,
    path: "/contact-us",
    exact: true,
    component: lazy(() => import("./Pages/SiteLinks/Components/ContactUs")),
  },
  {
    key: 11,
    path: "/flight-search",
    exact: true,
    component: lazy(() => import("./Pages/SiteLinks/Components/FlightSearch")),
  },
  {
    key: 12,
    path: "/equote",
    exact: true,
    component: lazy(() => import("./Pages/SiteLinks/Components/EQuote")),
  },
  {
    key: 13,
    path: "/ebooking",
    exact: true,
    component: lazy(() => import("./Pages/SiteLinks/Components/EBooking")),
  },
  {
    key: 14,
    path: "/track-and-trace",
    exact: true,
    component: lazy(() => import("./Pages/SiteLinks/Components/TrackAndTrace")),
  },
  {
    key: 15,
    path: "/all-rates",
    exact: true,
    component: lazy(() => import("./Pages/SiteLinks/Components/Rates")),
  },
];

function ProvideAuth({ children }: { children: React.ReactElement | null }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}
function ProvideErrorHandler({
  children,
}: {
  children: React.ReactElement | null;
}) {
  const msg = useProvideErrorHandler();

  return (
    <errorHandlerContext.Provider value={msg}>
      <Snackbar
        alertOpen={msg?.msgConfig.alertOpen}
        handleClose={msg?.handleClose}
        message={msg?.msgConfig.message}
        severity={msg?.msgConfig.severity}
      />
      {children}
    </errorHandlerContext.Provider>
  );
}

function App() {
  (window as TWindowWithDataLayer).dataLayer.push({
    config: "G-SN0GM28678",
    js: new Date(),
    event: "pageview",
  });
  const history = useHistory();
  const [count, setCount] = useState<number>(0);

  const timeoutRef = useRef<any>(null);
  const intervalRef = useRef<any>(null);
  const TIMEOUT_MS = 15 * 60 * 1000; // 15 minutes in milliseconds

  useEffect(() => {
    const targetUrls = [
      "http://localhost:3000/",
      "https://staging.tricargo.aero/",
      "https://tricargo.aero/"
    ];
    if (window.screen.width >= 1000 && window.screen.width <= 1366 &&targetUrls.includes(window.location.href)) {
      document.body.classList.add("custom-url-style");
    }
    else {
      document.body.classList.remove("custom-url-style");
    }
  }, [window.location.href])

  const resetTimer = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      localStorage.clear();
      localStorage.setItem("sessionTimeout", "true");
      window.location.reload();
    }, TIMEOUT_MS);
  };

  const handleActivity = () => {
    resetTimer();
  };

  useEffect(() => {
    resetTimer();

    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keydown", handleActivity);
    window.addEventListener("click", handleActivity);

    intervalRef.current = setInterval(() => {
      const remainingTime = timeoutRef.current - Date.now();
      if (Math.ceil(remainingTime / 1000) === 0) {
        localStorage.clear();
        localStorage.setItem("sessionTimeout", "true");
        window.location.reload();
      }
    }, 500);

    return () => {
      clearInterval(intervalRef.current);
      clearTimeout(timeoutRef.current);
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keydown", handleActivity);
      window.removeEventListener("click", handleActivity);
    };
  }, []);

  //@ts-ignore
  historyRef.current = history;
  return (
    <ProvideAuth>
      <ProvideErrorHandler>
        <ThemeProvider theme={theme}>
          <div className="App">
            <GlobalStyle />
            <Suspense fallback={<Loading />}>
              <Router>
                <Switch>
                  <Route path="/" component={Landing} exact />
                  <Route path="/solutions" component={Landing} exact />
                  <Route path="/howitworks" component={Landing} exact />
                  <Route path="/forairlines" component={Landing} exact />
                  <Route path="/customers" component={Landing} exact />
                  <Route path="/about" component={Landing} exact />
                  <Route path="/contact" component={Landing} exact />
                  <Route path="/terms" component={Terms} exact />
                  <Route path="/verify/:token" component={Verify} exact />
                  <Route path="/pdf" component={PdfViewer} exact />
                  {PublicRoutesList.map((route) => (
                    <PublicRoute {...route} />
                  ))}
                  {PrivateRoutesList.map((route) => (
                    <PrivateRoute {...route} />
                  ))}
                  <Redirect exact from="/*" to="/getQuote" />
                </Switch>
              </Router>
            </Suspense>
          </div>
        </ThemeProvider>
      </ProvideErrorHandler>
    </ProvideAuth>
  );
}

function PrivateRoute({ component: Component, ...rest }: any) {
  const auth = useAuth();
  const companytype = auth?.userConfig?.companytype;
  const userid = auth?.userConfig?.userid;
  const path = rest.path;
  return (
    <Route
      {...rest}
      render={({ location }) =>
        checkValidity(companytype, path, userid) ? (
          <Component {...rest} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

function PublicRoute({ component: Component, ...rest }: any) {
  const auth = useAuth();
  const userid = auth?.userConfig?.userid;

  return (
    <Route
      {...rest}
      render={({ location }) =>
        !!isNaN(Number(userid)) ? (
          <Component {...rest} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default App;
